import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './App.css';
import Header from './Header';
import Footer from './Footer';
import Home from './Home';
import Work from './Work';

class App extends Component {
    render() {
        return (
            <Router>
                <div className="app">
                    <Header />
                    <div className="container-fluid content">
                        <Route exact path="/" component={(props) => <Home {...props} />} />
                        <Route exact path="/Home" component={(props) => <Home {...props} />} />
                        <Route exact path="/Work" component={(props) => <Work {...props} />} />
                    </div>
                    <Footer />
                </div>
            </Router>
            //<Router>
            //    <div className="app">
            //        <div>
            //            <h5>본 PDF 파일의 내용은</h5>
            //            <h5>https://www.windbell.co.kr/</h5>
            //            <h5>에서 올바르게 볼 수 있습니다.</h5>
            //            <h5>김도홍</h5>
            //            <h5>workthread.start@gmail.com</h5>
            //            <h5>https://github.com/windbella</h5>
            //            <h5>010-4635-4365</h5>
            //        </div>
            //        <div className="container-fluid content">
            //            <Route exact path="/" component={(props) => <Home {...props} />} />
            //            <Route exact path="/" component={(props) => <Work {...props} />} />
            //        </div>
            //        <Footer />
            //    </div>
            //</Router>
        );
    }
}

export default App;