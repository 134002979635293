import { createAction, handleActions } from 'redux-actions';

const TOGGLE_TECH = 'work/TOGGLE_TECH';

export const toggleTech = createAction(TOGGLE_TECH, tech => tech);

const initialState = {
    techList: {
        'WEB': false,
        'ANDROID': false,
        'IOS': false,
        'WIN FORM': false,
        'Windows Service': false,
        'WCF': false,
        'C#': false,
        'JAVA': false,
        'OBJECT-C': false,
        'SWIFT': false,
        'Node.js': false,
        'Express': false,
        'MVC5': false,
        'ASP.NET': false,
        'Vue': false,
        'React': false,
        'jQuery': false,
        'Bootstrap': false,
        'MYSQL': false,
        'MSSQL': false,
        'PUSH': false
    },
    workList: [
        {
            category: 'personal',
            images:
                [
                    '/img/work/steamsale_new/1.png',
                    '/img/work/steamsale_new/2.png',
                    '/img/work/steamsale_new/3.png',
                    '/img/work/steamsale_new/4.png',
                    '/img/work/steamsale_new/5.png'
                ],
            title: 'STEAM SALE (리뉴얼)',
            date: '2019',
            keywords: ['WEB', 'ANDROID', 'IOS', 'MVC5', 'Vue', 'Vuex', 'MYSQL', 'Scraping', 'SPA'],
            contents: '게임 세일 정보 제공 사이트 및 모바일 앱\n웹 크롤러(C#)를 통해서 데이터 수집',
            contribution: '1인 개발 (웹/모바일)',
            links: [
                { name: '웹 사이트', url: 'https://steamsale.windbell.co.kr/' },
                { name: '플레이 스토어', url: 'https://play.google.com/store/apps/details?id=kr.co.windbell.steamsaleandroid' },
                { name: '앱 스토어', url: 'https://itunes.apple.com/kr/app/the-s-files-%EC%8A%A4%ED%8C%80-%ED%95%A0%EC%9D%B8-%EC%A0%95%EB%B3%B4/id1249986487?mt=8' }
            ]
        },
        {
            category: 'personal',
            title: 'PORTPOLIO',
            date: '2018.12',
            keywords: ['WEB', 'Node.js', 'Express', 'React', 'Redux', 'SPA'],
            contents: '현재 포트폴리오 사이트',
            contribution: '1인 개발'
        },
        {
            category: 'personal',
            images:
                [
                    '/img/work/mypinku/1.jpg',
                    '/img/work/mypinku/2.jpg',
                    '/img/work/mypinku/3.jpg',
                    '/img/work/mypinku/4.jpg',
                    '/img/work/mypinku/5.jpg'
                ],
            title: 'MY PINK UNIVERSE',
            date: '2018',
            keywords: ['WEB', 'Node.js', 'Express', 'Vue', 'MYSQL', 'SPA'],
            contents: '일러스트레이터 사이트',
            contribution: '1인 개발 (관리자 포함)',
            links: [{ name: '웹 사이트', url: 'https://www.mypinku.com/' }],
        },
        {
            category: 'personal',
            images:
                [
                    '/img/work/steamsale/1.jpg',
                    '/img/work/steamsale/2.jpg',
                    '/img/work/steamsale/3.jpg',
                    '/img/work/steamsale/4.jpg',
                    '/img/work/steamsale/5.jpg',
                    '/img/work/steamsale/6.jpg'
                ],

            title: 'STEAM SALE',
            date: '2017',
            keywords: ['WEB', 'ANDROID', 'IOS', 'ASP.NET', 'ASMX', 'jQuery', 'MYSQL', 'Scraping'],
            contents: '게임 세일 정보 제공 사이트 및 모바일 앱\n웹 크롤러(C#)를 통해서 데이터 수집',
            contribution: '1인 개발 (웹/모바일)'
        },
        {
            category: 'in-company',
            images:
                [
                    '/img/work/sunny/1.jpg',
                    '/img/work/sunny/2.jpg',
                    '/img/work/sunny/3.jpg',
                    '/img/work/sunny/4.jpg',
                    '/img/work/sunny/5.jpg',
                    '/img/work/sunny/6.jpg',
                    '/img/work/sunny/7.jpg',
                    '/img/work/sunny/8.jpg',
                    '/img/work/sunny/9.jpg',
                    '/img/work/sunny/10.jpg',
                    '/img/work/sunny/11.jpg',
                    '/img/work/sunny/12.jpg'
                ],
            title: '신한은 야구다',
            date: '2018.2 ~ 2018.3',
            keywords: ['WEB', 'ASP.NET', 'ASMX', 'Vue', 'MSSQL'],
            contents: 'KBO(야구) 기록 웹 페이지 (웹뷰/IN-IFRAME)',
            contribution: '프론트엔드 (100%) / 백엔드 (70%)',
            links: [
                { name: '플레이 스토어', url: 'https://play.google.com/store/apps/details?id=com.shinhan.sbanking' },
                { name: '앱 스토어', url: 'https://itunes.apple.com/kr/app/%EC%8B%A0%ED%95%9C-%EC%8F%A0-sol-%EC%8B%A0%ED%95%9C%EC%9D%80%ED%96%89-%EC%8A%A4%EB%A7%88%ED%8A%B8%ED%8F%B0%EB%B1%85%ED%82%B9/id357484932?mt=8' }
            ]
        },
        {
            category: 'in-company',
            images:
                [
                    '/img/work/ktotm/1.jpg',
                    '/img/work/ktotm/2.jpg',
                    '/img/work/ktotm/3.jpg',
                    '/img/work/ktotm/4.jpg',
                    '/img/work/ktotm/5.jpg',
                    '/img/work/ktotm/6.jpg',
                    '/img/work/ktotm/7.jpg',
                    '/img/work/ktotm/8.jpg',
                    '/img/work/ktotm/9.jpg',
                    '/img/work/ktotm/10.jpg',
                    '/img/work/ktotm/11.jpg',
                    '/img/work/ktotm/12.jpg',
                    '/img/work/ktotm/13.jpg',
                    '/img/work/ktotm/14.jpg',
                    '/img/work/ktotm/15.jpg',
                    '/img/work/ktotm/16.jpg',
                    '/img/work/ktotm/17.jpg',
                    '/img/work/ktotm/18.jpg',
                    '/img/work/ktotm/19.jpg',
                    '/img/work/ktotm/20.jpg',
                    '/img/work/ktotm/21.jpg',
                    '/img/work/ktotm/22.jpg',
                    '/img/work/ktotm/23.jpg',
                    '/img/work/ktotm/24.jpg',
                    '/img/work/ktotm/25.jpg',
                    '/img/work/ktotm/26.jpg'
                ],
            title: '올레 TV 모바일 프로야구',
            date: '2018.1 ~ 2018.2',
            keywords: ['WEB', 'ASP.NET', 'ASMX', 'jQuery', 'MSSQL'],
            contents: 'KBO(야구) 라이브를 시청하면서 기록 비교 및 채팅을 할 수 있는 웹 페이지 (웹뷰)',
            contribution: '채팅 구현 / CANVAS 그래픽 코어 / 프론트엔드 (30%) / 백엔드 (30%)',
            links: [
                { name: '플레이 스토어', url: 'https://play.google.com/store/apps/details?id=com.kt.otv' },
                { name: '앱 스토어', url: 'https://itunes.apple.com/kr/app/%EC%98%AC%EB%A0%88-tv-%EB%AA%A8%EB%B0%94%EC%9D%BC/id438653868?mt=8' }
            ]
        },
        {
            category: 'in-company',
            images:
                [
                    '/img/work/solpick/1.jpg',
                    '/img/work/solpick/2.jpg',
                    '/img/work/solpick/3.jpg',
                    '/img/work/solpick/4.jpg',
                    '/img/work/solpick/5.jpg',
                    '/img/work/solpick/6.jpg',
                    '/img/work/solpick/7.jpg',
                    '/img/work/solpick/8.jpg',
                    '/img/work/solpick/9.jpg',
                    '/img/work/solpick/10.jpg',
                    '/img/work/solpick/11.jpg'
                ],
            title: '신한 쏠 픽',
            date: '2018.10 ~ 2018.11',
            keywords: ['WEB', 'ASP.NET', 'ASMX', 'jQuery', 'MSSQL'],
            contents: 'KBO(야구) 퀴즈 웹 페이지 (웹뷰)',
            contribution: '구조 설계 / 프론트엔드 (70%) / 백엔드 (50%)',
            links: [
                { name: '플레이 스토어', url: 'https://play.google.com/store/apps/details?id=com.shinhan.sbanking' },
                { name: '앱 스토어', url: 'https://itunes.apple.com/kr/app/%EC%8B%A0%ED%95%9C-%EC%8F%A0-sol-%EC%8B%A0%ED%95%9C%EC%9D%80%ED%96%89-%EC%8A%A4%EB%A7%88%ED%8A%B8%ED%8F%B0%EB%B1%85%ED%82%B9/id357484932?mt=8' }
            ]
        },
        {
            category: 'in-company',
            images:
                [
                    '/img/work/shinhan/1.jpg',
                    '/img/work/shinhan/2.jpg',
                    '/img/work/shinhan/3.jpg',
                    '/img/work/shinhan/4.jpg',
                    '/img/work/shinhan/5.jpg',
                    '/img/work/shinhan/6.jpg'
                ],
            title: '신한 베이스볼 파크',
            date: '2018.10 ~ 2018.11',
            keywords: ['WEB', 'ASP.NET', 'ASMX', 'jQuery', 'MSSQL'],
            contents: 'KBO(야구) 기록 & 이벤트 웹 페이지 (웹뷰)',
            contribution: '구조 설계 / 프론트엔드 (30%) / 백엔드 (30%)',
            links: [
                { name: '플레이 스토어', url: 'https://play.google.com/store/apps/details?id=com.shinhan.sbanking' },
                { name: '앱 스토어', url: 'https://itunes.apple.com/kr/app/%EC%8B%A0%ED%95%9C-%EC%8F%A0-sol-%EC%8B%A0%ED%95%9C%EC%9D%80%ED%96%89-%EC%8A%A4%EB%A7%88%ED%8A%B8%ED%8F%B0%EB%B1%85%ED%82%B9/id357484932?mt=8' }
            ]
        },
        {
            category: 'in-company',
            images:
                [
                    '/img/work/idea/1.png',
                    '/img/work/idea/2.png'
                ],

            title: '사내 아이디어 게시판',
            date: '2018.09',
            keywords: ['WEB', 'ASP.NET', 'MVC5', 'WEB API 2', 'MSSQL'],
            contents: 'MVC5와 RAZOR 엔진 그리고 API 2  라이브러리를 이용한 게시판',
            contribution: '1인 개발'
        },
        {
            category: 'in-company',
            images:
                [
                    '/img/work/service/1.png',
                    '/img/work/service/2.png',
                    '/img/work/service/3.png'
                ],

            title: '서비스 관리자 사이트',
            date: '2018.04 ~ 2018.10',
            keywords: ['WEB', 'ASP.NET', 'MVC5', 'WEB API 2', 'Bootstrap', 'MSSQL'],
            contents: 'MVC5와 Bootstrap을 활용한 사내 서비스(백그라운드) 관리자 사이트\n아래 서비스 라이브러리와 연동',
            contribution: '1인 개발'
        },
        {
            category: 'in-company',
            title: '서비스 라이브러리',
            date: '2016.10 ~',
            keywords: ['C#', 'WCF', 'Windows Service', 'JSON', 'XML', 'DB To DB', 'PUSH'],
            contents:
                '사내 백그라운드 서비스를 위한 라이브러리\\n' +
                'SENDER : DB To DB로 데이터를 옮기는 서비스 (MSSQL, MYSQL, ORACLE 지원, 데이터 병합 기능)\\n' +
                'PARSER : XML이나 JSON 형태의 데이터를 DB로 삽입하는 서비스\\n' +
                'PUSH LIBRARY : ANDROID나 IOS에 푸쉬 메세지를 보내거나, 사내 오류 알림 서비스로 경고를 보내는 시스템 라이브러리\\n' +
                'API LIBRARY : DB 테이블 데이터를 JSON 출력 API로 쉽게 변경해 주는 라이브러리',
            contribution: '개발 주도 및 관리 (95%)'
        },
        {
            category: 'in-company',
            title: '신규 구단 솔루션',
            date: '2018.03 ~ 2018.05',
            keywords: ['C#', 'WIN FORM', 'MSSQL'],
            contents: '야구 전력 분석을 위한 경기 데이터 입력기',
            contribution: '1인 개발'
        },
        {
            category: 'in-company',
            title: '신규 KBL 입력기',
            date: '2017.08 ~ 2017.11',
            keywords: ['C#', 'WIN FORM', 'MSSQL'],
            contents: '농구 기록 생성을 위한 경기 데이터 입력기',
            contribution: '구조 설계 / 개발 (70%)'
        },
        {
            category: 'in-company',
            images:
                [
                    '/img/work/kbostats/1.png',
                    '/img/work/kbostats/2.png',
                    '/img/work/kbostats/3.png',
                    '/img/work/kbostats/4.png',
                    '/img/work/kbostats/5.png'
                ],
            title: 'KBO STATS (리뉴얼)',
            date: '2017.01 ~ 2017.03',
            keywords: ['ANDROID', 'IOS', 'JAVA', 'OBJECT-C'],
            contents: '야구 공식 기록 앱',
            contribution: '개발(30%) 및 유지보수 지원',
            links: [
                { name: '플레이 스토어', url: 'https://play.google.com/store/apps/details?id=com.sports2i' },
                { name: '앱 스토어', url: 'https://itunes.apple.com/kr/app/kbo-stats/id610317366?mt=8' }
            ]
        },
        {
            category: 'in-company',
            images:
                [
                    '/img/work/btl/1.png',
                    '/img/work/btl/2.png',
                    '/img/work/btl/3.png',
                    '/img/work/btl/4.png',
                    '/img/work/btl/5.png'
                ],
            title: '비더레전드 유지보수',
            date: '2017.01 ~',
            keywords: ['ANDROID', 'IOS', 'JAVA', 'OBJECT-C'],
            contents: '비더레전드(야구 퀴즈/이벤트/커뮤니티) 유지보수 및 이벤트 페이지 추가',
            contribution: '유지보수 지원',
            links: [
                { name: '플레이 스토어', url: 'https://play.google.com/store/apps/details?id=com.sports2i.btlmanager' },
                { name: '앱 스토어', url: 'https://itunes.apple.com/kr/app/%EB%B9%84%EB%8D%94%EB%A0%88%EC%A0%84%EB%93%9C/id966407669?mt=8' }
            ]
        },
        {
            category: 'in-company',
            title: '야구학교 입력기',
            date: '2017.01 ~ 2017.02',
            keywords: ['C#', 'WIN FORM', 'MSSQL'],
            contents: '야구학교 학생 기록 관리를 위한 입력기',
            contribution: '1인 개발'
        },
        {
            category: 'in-company',
            images:
                [
                    '/img/work/academy/1.png',
                    '/img/work/academy/2.png',
                    '/img/work/academy/3.png'
                ],
            title: '야구학교 사이트',
            date: '2016.08 ~ 2017.11',
            keywords: ['WEB', 'ASP.NET', 'ASMX', 'jQuery', 'MSSQL'],
            contents: '야구학교 안내 사이트\n관리자 페이지 개발 및 메인 페이지 스케줄 페이지 제작',
            contribution: '관리자 페이지(100%) / 메인 페이지(20%) / 푸쉬 서버',
            links: [{ name: '웹 사이트', url: 'https://www.academy2i.com/' }]
        },
        {
            category: 'in-company',
            images:
                [
                    '/img/work/sms/1.png',
                    '/img/work/sms/2.png',
                    '/img/work/sms/3.jpg',
                    '/img/work/sms/4.jpg',
                    '/img/work/sms/5.jpg'
                ],
            title: 'SMS 앱',
            date: '2016.08 ~ 2016.11',
            keywords: ['IOS', 'SWIFT'],
            contents: '사내 관리 앱 IOS 버전 (휴가/외근 관리 및 시스템 오류 알림)',
            contribution: '1인 개발 / 푸쉬 서버'
        },
        {
            category: 'in-company',
            title: '옵션입력기',
            date: '2016.03 ~',
            keywords: ['C#', 'WIN FORM', 'MSSQL'],
            contents: '야구 공식 기록을 위한 추가 데이터 입력기',
            contribution: '유지보수'
        },
        {
            category: 'in-company',
            title: '구단솔루션',
            date: '2016.01 ~',
            keywords: ['C#', 'WIN FORM', 'MSSQL'],
            contents: '야구 전력 분석을 위한 경기 데이터 입력기',
            contribution: '유지보수'
        }
    ]
};

export default handleActions(
    {
        [TOGGLE_TECH]: (state, action) => {
            const techList = { ...state.techList };
            techList[action.payload] = !techList[action.payload];
            return {
                ...state,
                techList
            };
        }
    },
    initialState
)