import React, { Component } from 'react';
import Swiper from 'swiper';
import './swiper.min.css';
import './WorkItem.css';

class WorkItem extends Component {
    constructor(props) {
        super(props);
        this.swiper = React.createRef();
    }

    componentDidMount() {
        new Swiper(this.swiper.current, {
            direction: 'horizontal',
            slidesPerView: 'auto',
            spaceBetween: 0,
            freeMode: true,
            loop: false,
            scrollbar: {
                el: '.swiper-scrollbar',
            }
        });
    }

    render() {
        return (
            <div className="work-item">
                <div className="card text-left">
                    {this.props.images && this.props.images.length > 0 && (
                        <div className="swiper-container" ref={this.swiper}>
                            <div className="swiper-wrapper">
                                {this.props.images.map((v, i) => {
                                    return (<div className="swiper-slide" key={i}><img src={v} alt='' /></div>)
                                })}
                                <div className="swiper-scrollbar"></div>
                            </div>
                        </div>
                    )}
                    <div className="card-body">
                        <h4 className="card-title mb-2">{this.props.title}</h4>
                        <h6 className="card-subtitle mb-2 text-muted">{this.props.date}</h6>
                        {this.props.keywords && this.props.keywords.length > 0 && (
                            <h6 className="card-subtitle mb-3 text-muted">
                                {this.props.keywords.map((v, i) => {
                                    return (<span className="badge badge-secondary" key={i}>{v}</span>)
                                })}
                            </h6>
                        )}
                        {this.props.contents.split('\\n').map((v, i) => {
                            return (<p className="card-text mb-2" key={i}>{v}</p>)
                        })}
                        <p className="card-text text-muted mb-2">{this.props.contribution}</p>
                        {this.props.links && this.props.links.length > 0 && (
                            <div>
                                {this.props.links.map((v, i) => {
                                    return (<a href={v.url} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-dark mr-1 mb-1" key={i}>{v.name} <i className="link-ma">navigate_next</i></a>)
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default WorkItem;