import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import './Header.css';
import $ from 'jquery';

class Header extends Component {
    componentDidMount() {
        $('#link-resume').tooltip({ trigger: 'manual' });
        $('#link-resume').click(function (e) {
            e.preventDefault();
            $(this).tooltip('toggle');
        });
    }

    render() {
        const { location } = this.props;
        const segments = location.pathname.split('/');
        return (
            <nav className="navbar navbar-expand fixed-top navbar-light header">
                <div className="navbar-brand"></div>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item">
                            <Link className={('nav-link') + (segments.length > 1 && (segments[1] === '' || segments[1] === 'Home') ? ' active' : '')} to="/Home" id="link-home">Home</Link>
                        </li>
                    </ul>
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className={('nav-link') + (segments.length > 1 && segments[1] === 'Work' ? ' active' : '')} to="/Work" id="link-work">Work</Link>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link disabled" href="/" data-toggle="tooltip" data-placement="bottom" title="Closed" id="link-resume">Resum</a>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}

export default withRouter(Header);