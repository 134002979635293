import React, { Component } from 'react';
import './Home.css';
import Title from './Title';
import SkillItem from './SkillItem';
//import WorkPreview from './WorkPreview'
import LinkBar from './LinkBar';

class Home extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="home">
                <div className="instroduce">
                    <Title text="ABOUT ME" />
                    <div>
                        <p><span>15</span>년 전 취미로 프로그래밍을 시작해</p>
                        <p><span>5</span>년 전 전공으로 택하고</p>
                        <p><span>3</span>년 전부터 개발자를 하고 있는 사람입니다.</p>
                    </div>
                    <br />
                    <div>
                        <h4>A rolling stone gathers no moss.</h4>
                        <h5>"구르는 개발자에는 이끼가 끼지 않는다."</h5>
                        <p>기술이 발전하는 만큼 개발 트렌드는 더 편하고 효율적이게 흘러왔습니다.</p>
                        <p>그 흐름에 맞춰 저도 열심히 굴러보고 있습니다.</p>
                    </div>
                    <br />
                    <div>
                        <h4>Divide & Conquer</h4>
                        <h5>"분할 정복"</h5>
                        <p>문제를 해결하기 위한 가장 기본적이고 강력한 방법입니다.</p>
                        <p>이를 잘하기 위해서는 넓은 배경지식이 가장 중요했습니다.</p>
                        <p>그래서 오늘도 구르고있습니다.</p>
                    </div>
                </div>
                <div className="skill text-left">
                    <Title text="SKILL" />
                    <SkillItem language="C#" level="100" />
                    <SkillItem language="JAVA" level="70" />
                    <SkillItem language="Javascript (ES6, React, Vue)" level="90" />
                    <SkillItem language="Swift" level="60" />
                    <SkillItem language="Object-C" level="50" />
                    <SkillItem language="Kotlin" level="20" />
                    <SkillItem language="Python" level="10" />
                    <SkillItem language="MSSQL" level="90" />
                    <SkillItem language="MYSQL" level="70" />
                    <SkillItem language="ORACLE " level="30" />
                </div>
                <div>
                    <LinkBar />
                </div>
            </div>
        );
    }
}

export default Home;