import React, { Component } from 'react';
import './Title.css';

class Title extends Component {
    render() {
        return (
            <div className="title">
                <h3>{this.props.text}</h3>
            </div>
        );
    }
}

export default Title;