import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <p>© 2018 Windbell. All rights reserved.</p>
            </div>
        );
    }
}

export default Footer;