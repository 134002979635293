import React, { Component } from 'react';
import $ from 'jquery';
import './SkillItem.css';

class SkillItem extends Component {
    constructor(props) {
        super(props);
        this.progress = React.createRef();
    }

    componentDidMount() {
        $(this.progress.current).css('transition', 'none');
        $(this.progress.current).css('width', '0%');
        setTimeout(() => {
            $(this.progress.current).css('transition', '');
            $(this.progress.current).css('width', this.props.level + '%');
        }, 1000);
    }

    render() {
        return (
            <div className="skill-item">
                <h6 className="mb-1">{this.props.language}</h6>
                <div className="progress mb-2">
                    <div ref={this.progress} className="progress-bar bg-success" role="progressbar" style={{ width: this.props.level + '%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
        );
    }
}

export default SkillItem;