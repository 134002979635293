import React, { Component } from 'react';
import $ from 'jquery';
import './LinkBar.css';

class LinkBar extends Component {
    componentDidMount() {
        $('#link-email, #link-phone, #link-github').tooltip({ trigger: 'manual' });
        $('#link-email, #link-phone, #link-github').click(function (e) {
            e.preventDefault();
            $(this).parent().siblings().find('a').removeClass('active').tooltip('hide');
            $(this).tooltip('toggle');
            if ($(this).hasClass('active')) {
                $(this).removeClass('active')
            } else {
                $(this).addClass('active')
            }
        });
    }

    componentWillUnmount() {
        $('#link-email, #link-phone, #link-github').tooltip('dispose');
    }

    render() {
        return (
            <div className="link-bar">
                <ul className="nav justify-content-center">
                    <li className="nav-item">
                        <a href="/" data-toggle="tooltip" data-placement="bottom" title="" id="link-github" data-original-title="https://github.com/windbella"><div className="ico-round" style={{ 'backgroundImage': 'url(/img/link_github.png)' }}></div></a>
                    </li>
                    <li className="nav-item">
                        <a href="/" data-toggle="tooltip" data-placement="bottom" title="" id="link-email" data-original-title="workthread.start@gmail.com"><div className="ico-round" style={{ 'backgroundImage': 'url(/img/link_mail.png)' }}></div></a>
                    </li>
                    <li className="nav-item">
                        <a href="/" data-toggle="tooltip" data-placement="bottom" title="" id="link-phone" data-original-title="010-4635-4365"><div className="ico-round" style={{ 'backgroundImage': 'url(/img/link_phone.png)' }}></div></a>
                    </li>
                </ul>
            </div>
        );
    }
}

export default LinkBar;