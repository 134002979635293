import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleTech } from '../store/modules/work';
import Title from './Title';
import TechItem from './TechItem';
import WorkItem from './WorkItem';
import './Work.css';

class Work extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { techList, workList, toggleTech } = this.props;
        const isFilter = Object.keys(techList).some((tech) => techList[tech]);
        const techItemList = Object.keys(techList)
            .map(tech => (
                <TechItem
                    name={tech}
                    isSelected={techList[tech]}
                    key={tech}
                    toggleTech={toggleTech}
                />
            ));
        const personalWorkList = workList
            .filter(work => work.category === 'personal')
            .filter(work => {
                if (isFilter) {
                    return Object.keys(techList).some((tech) => {
                        if (techList[tech] && work.keywords.includes(tech)) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                } else {
                    return true;
                }
            })
        const personalWorkItemList = personalWorkList.length > 0 ?
            personalWorkList
                .map(work => (
                    <WorkItem
                        images={work.images}
                        title={work.title}
                        date={work.date}
                        keywords={work.keywords}
                        contents={work.contents}
                        contribution={work.contribution}
                        links={work.links}
                        key={work.title}
                    />
                ))
            : (<h5>결과가 없습니다.</h5>);
        const inCompnayWorkList = workList
            .filter(work => work.category === 'in-company')
            .filter(work => {
                if (isFilter) {
                    return Object.keys(techList).some((tech) => {
                        if (techList[tech] && work.keywords.includes(tech)) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                } else {
                    return true;
                }
            })
        const inCompnayWorkItemList = inCompnayWorkList.length > 0 ?
            inCompnayWorkList
                .map(work => (
                    <WorkItem
                        images={work.images}
                        title={work.title}
                        date={work.date}
                        keywords={work.keywords}
                        contents={work.contents}
                        contribution={work.contribution}
                        links={work.links}
                        key={work.title}
                    />
                ))
            : (<h5>결과가 없습니다.</h5>);
        return (
            <div className="work">
                <div>
                    <Title text="KEYWORD" />
                    <h4>
                        {techItemList}
                    </h4>
                </div>
                <div>
                    <Title text="PERSONAL" />
                    {personalWorkItemList}
                </div>
                <div>
                    <Title text="IN-COMPANY" />
                    {inCompnayWorkItemList}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ work }) => ({
    techList: work.techList,
    workList: work.workList
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({ toggleTech }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Work);